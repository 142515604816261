import React from 'react';
import {Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {dtStrExp} from '../../Utils/TimeUtil';
import * as AppActions from '../../AppActions';

export default function AdminLunchExport(props) {
  const {generateExcelFile} = props;

  const [isFetching, setIsFetching] = React.useState(false);
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [products, setProducts] = React.useState(null);
  const [link, setLink] = React.useState('');

  const fetchProducts = async () => {
    if (!dtStrExp.test(start) || !dtStrExp.test(end)) {
      message.warning('日期格式錯誤');
      return;
    }

    try {
      AppActions.setLoading(true);
      setIsFetching(true);
      setProducts(null);
      setLink('');

      const products = await JStorage.aggregateDocuments('product', [
        {
          $match: {
            archived: {$ne: true},
            session: {$exists: true},
            'session.date': {
              $gte: start,
              $lte: end,
            },
            $or: [
              {name: {$regex: '午間小品'}},
              {internal_label: {$regex: '午間小品'}},
            ],
          },
        },
        {
          $project: {
            name: 1,
            internal_label: 1,
            labels: 1,
            session: 1,
          },
        },
        {
          $lookup: {
            from: 'order',
            foreignField: 'items.0.product.id',
            localField: '_id',
            as: 'orders',
          },
        },
        {
          $addFields: {
            orders_success: {
              $filter: {
                input: '$orders',
                as: 'order',
                cond: {
                  $eq: ['$$order.payment_status', 'success'],
                },
              },
            },
          },
        },
        {
          $project: {
            name: 1,
            internal_label: 1,
            labels: 1,
            session: 1,
            orders: {
              $size: '$orders',
            },
            orders_success: {
              $size: '$orders_success',
            },
          },
        },
        {
          $sort: {
            orders_success: -1,
          },
        },
      ]);

      setProducts(products);
    } catch (err) {
      console.warn(err);
      message.error('發生錯誤');
    } finally {
      AppActions.setLoading(false);
      setIsFetching(false);
    }
  };

  const createExcelFile = async () => {
    const link = await generateExcelFile(
      products,
      ['名稱', '場次日期', '分類', '公會內部分類', '報名人數'],
      (r) => {
        return [
          r.name,
          `${r.session.date}`,
          `${(() => {
            const labels = [...r.labels];
            let text = '';

            if (Array.isArray(labels) && labels.length > 0) {
              for (let i = 0; i < labels.length; i++) {
                text += `${labels[i]}${i + 1 !== labels.length ? '、' : ''}`;
              }
            }

            return text;
          })()}`,
          r.internal_label,
          r.orders_success,
        ];
      },
    );

    setLink(link);
  };

  return (
    <div className="item-wrapper">
      <div className="title">午間小品</div>
      <div>課程名稱關鍵字：午間小品</div>
      <div>公會內部分類：午間小品</div>

      <div className="container">
        <div className="content">
          開始日期：
          <input
            type="date"
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
        </div>

        <div className="content">
          結束日期：
          <input
            type="date"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>

        <div className="content">
          {products ? (
            <div>
              <div>總場次：{products.length} 場</div>
              <div>
                報名人數最多：
                <div>
                  {products
                    .filter((_, i) => i < 3)
                    .map((p) => (
                      <div key={p.id}>
                        {p.orders_success}人/{p.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>總場次：--- 場</div>
              <div>報名人數最多：--- 人</div>
            </div>
          )}
        </div>

        {isFetching ? (
          '查詢中......'
        ) : (
          <div className="content">
            <Button type="primary" onClick={fetchProducts}>
              查詢
            </Button>

            {products && (
              <Button
                style={{marginLeft: 10, marginRight: 10}}
                onClick={createExcelFile}>
                產生報表
              </Button>
            )}

            {link && (
              <Button
                type="link"
                href={link}
                download={`午間小品${new Date()
                  .toLocaleDateString('sv')
                  .replace('-', '')}.xlsx`}>
                下載報表
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
