import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {message} from 'antd';
import dayjs from 'dayjs';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import ExportHelper from '../TbaExportHelper';
import {formatDateStr, dtStrExp} from './TimeUtil';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserSexLabel,
  getUserPunishmentTypeLabel,
  getUserInformByLabel,
  getUserExperienceTbaPositionLabel,
  getUserDegree,
} from './UserUtil';
import schools from '../schools.json';
import expertises from '../expertises.json';

export const EXPORT_FIELDS = [
  {key: 'serial_number', name: '流水號'},
  {key: 'name', name: '姓名'},
  {key: 'NAME_ENG', name: '英文姓名'},
  {key: 'state', name: '狀態'},
  {key: 'substate', name: '次狀態'},
  {key: 'SEX', name: '性別'},
  {key: 'birthday', name: '生日'},
  {key: 'id_card_number', name: '身分證字號'},
  {key: 'phone', name: '手機'},
  {key: 'email', name: 'Email'},
  {key: 'EMAIL2', name: '備用Email'},
  {key: 'mail_address', name: '通訊地址'},
  {key: 'residence_address', name: '戶籍地址'},
  {key: 'company_current_name', name: '事務所名稱'},
  {key: 'company_current_address', name: '事務所地址'},
  {key: 'company_current_phone', name: '事務所電話1'},
  {key: 'company_current_fax', name: '事務所傳真1'},
  {key: 'ASSISTANT1', name: '助理1'},
  {key: 'ASSISTANT2', name: '助理2'},
  {key: 'INFORM_TO', name: '寄送地點'},
  {key: 'INFORM_BY', name: '寄送方式'},
  {key: 'seniority', name: '年資'},
  {key: 'age', name: '年齡'},
  {key: 'A_QUALIFY', name: '律師證書'},
  {key: 'QUALIFY_CTRY', name: '原資格國（地區）'},
  {key: 'join_start_date', name: '入會日期'},
  {key: 'join_end_date', name: '退會日期'},
  {key: 'cross_start_date', name: '執業開始日期'},
  {key: 'cross_end_date', name: '執業結束日期'},
  {key: 'punishments', name: '懲戒紀錄'},
  {key: 'experiences_tba', name: '本會經歷'},
];

export const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: [],
    properties: {
      qType: {
        title: '查詢方式',
        enum: ['資料異動', '入會區間', '退會區間', '擇定區間', '進階搜尋'],
      },
      sorting: {
        title: '排序',
        type: 'string',
        enum: ['-created', 'created'],
        enumNames: ['日期新到舊', '日期舊到新'],
      },
    },
    dependencies: {
      qType: {
        oneOf: [
          {
            properties: {
              qType: {
                enum: ['資料異動'],
              },
              startDate: {
                title: '資料異動起始日',
                type: 'string',
                format: 'date',
              },
              days: {
                title: '天數',
                type: 'number',
                enum: [1, 3, 8, 28, 29, 30, 31, 100, 120],
                enumNames: [
                  '當日',
                  '3日',
                  '8日',
                  '28日',
                  '29日',
                  '30日',
                  '31日',
                  '100日',
                  '120日',
                ],
              },
              is_twba_substate_6_setup: {
                type: 'boolean',
                title: '特殊設定：「外國法事務律師」匯出顯示「一般會員」',
              },
            },
          },
          {
            properties: {
              qType: {
                enum: ['入會區間'],
              },
              joinDateStart: {
                title: '入會日期(起)',
                type: 'string',
              },
              joinDateEnd: {
                title: '入會日期(迄)',
                type: 'string',
              },
              is_twba_substate_6_setup: {
                type: 'boolean',
                title: '特殊設定：「外國法事務律師」匯出顯示「一般會員」',
              },
            },
          },
          {
            properties: {
              qType: {
                enum: ['退會區間'],
              },
              exitDateStart: {
                title: '退會日期(起)',
                type: 'string',
              },
              exitDateEnd: {
                title: '退會日期(迄)',
                type: 'string',
              },
              is_twba_substate_6_setup: {
                type: 'boolean',
                title: '特殊設定：「外國法事務律師」匯出顯示「一般會員」',
              },
            },
          },
          {
            properties: {
              qType: {
                enum: ['擇定區間'],
              },
              transformDateStart: {
                title: '擇定日期(起)',
                type: 'string',
              },
              transformDateEnd: {
                title: '擇定日期(迄)',
                type: 'string',
              },
              is_twba_substate_6_setup: {
                type: 'boolean',
                title: '特殊設定：「外國法事務律師」匯出顯示「一般會員」',
              },
            },
          },
          {
            properties: {
              qType: {
                enum: ['進階搜尋'],
              },
              owner: {
                title: '會員',
                type: 'string',
              },
              states: {
                title: '狀態',
                type: 'array',
                uniqueItems: true,
                items: {
                  type: 'integer',
                  enum: [1, 2, 3, 4, 5, 6],
                  enumNames: [
                    '入會',
                    '退會',
                    '其他',
                    '實習律師',
                    '跨區',
                    '停止',
                  ],
                },
              },
              substates: {
                title: '次狀態',
                type: 'array',
                uniqueItems: true,
                items: {
                  type: 'string',
                  enum: ['1', '2', '3', '4', '5', '6', '7'],
                  enumNames: [
                    '主區會員',
                    '準會員',
                    '兼區會員',
                    '一般會員',
                    '特別會員',
                    '外國法事務律師',
                    '準特別會員',
                  ],
                },
              },
              sex: {
                title: '性別',
                type: 'string',
                enum: ['', 'M', 'F'],
                enumNames: ['請選擇', '男', '女'],
              },
              company_current: {
                title: '現在事務所',
                type: 'string',
              },
              school: {
                title: '學校',
                type: 'string',
                enum: ['', ...schools],
                enumNames: ['請選擇', ...schools],
              },
              expertise: {
                title: '專長',
                type: 'string',
                enum: ['', ...expertises],
                enumNames: ['請選擇', ...expertises],
              },
              seniority_baseline_date: {
                title: '年資基準日',
                type: ['null', 'string'],
                format: 'date',
              },
              seniority_start: {
                title: '年資區間（起）',
                type: ['null', 'integer'],
              },
              seniority_end: {
                title: '年資區間（迄）',
                type: ['null', 'integer'],
              },
              age_baseline_date: {
                title: '年齡基準日',
                type: ['null', 'string'],
                format: 'date',
              },
              age_start: {
                title: '年齡區間（起）',
                type: ['null', 'integer'],
              },
              age_end: {
                title: '年齡區間（迄）',
                type: ['null', 'integer'],
              },
              birthday_date_start: {
                title: '生日區間（起）',
                type: 'string',
                format: 'date',
              },
              birthday_date_end: {
                title: '生日區間（迄）',
                type: 'string',
                format: 'date',
              },
              expiry_date_start: {
                title: '許可證有效期限（起）',
                type: 'string',
                format: 'date',
              },
              expiry_date_end: {
                title: '許可證有效期限（迄）',
                type: 'string',
                format: 'date',
              },
              joinDateStart: {
                title: '入會日期（起）',
                type: 'string',
              },
              joinDateEnd: {
                title: '入會日期（迄）',
                type: 'string',
              },
              exitDateStart: {
                title: '退會日期(起)',
                type: 'string',
              },
              exitDateEnd: {
                title: '退會日期(迄)',
                type: 'string',
              },
              transformDateStart: {
                title: '擇定日期(起)',
                type: 'string',
              },
              transformDateEnd: {
                title: '擇定日期(迄)',
                type: 'string',
              },
              selectAllFields: {
                type: 'boolean',
                title: '匯出欄位全選',
                enum: [true, false],
                enumNames: ['是', '否'],
                default: true,
              },
            },
          },
        ],
      },
      seniority_baseline_date: {
        oneOf: [
          {
            required: ['seniority_start', 'seniority_end'],
            properties: {
              seniority_baseline_date: {
                type: 'string',
              },
            },
          },
        ],
      },
      seniority_start: {
        oneOf: [
          {
            required: ['seniority_start', 'seniority_end'],
            properties: {seniority_start: {type: 'integer'}},
          },
        ],
      },
      seniority_end: {
        oneOf: [
          {
            required: ['seniority_start', 'seniority_end'],
            properties: {seniority_end: {type: 'integer'}},
          },
        ],
      },
      age_baseline_date: {
        oneOf: [
          {
            required: ['age_start', 'age_end'],
            properties: {
              age_baseline_date: {
                type: 'string',
              },
            },
          },
        ],
      },
      age_start: {
        oneOf: [
          {
            required: ['age_start', 'age_end'],
            properties: {
              age_start: {
                type: 'integer',
              },
            },
          },
        ],
      },
      age_end: {
        oneOf: [
          {
            required: ['age_start', 'age_end'],
            properties: {age_end: {type: 'integer'}},
          },
        ],
      },
      selectAllFields: {
        oneOf: [
          {
            required: ['fields'],
            properties: {
              selectAllFields: {enum: [false]},
              fields: {
                title: '匯出欄位選擇',
                type: 'array',
                uniqueItems: true,
                items: {
                  type: 'string',
                  enum: EXPORT_FIELDS.map((field) => field.key),
                  enumNames: EXPORT_FIELDS.map((field) => field.name),
                },
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    joinDateStart: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    joinDateEnd: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    exitDateStart: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    exitDateEnd: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    transformDateStart: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    transformDateEnd: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    owner: {
      'ui:widget': 'admin-select-user-custom-widget',
    },
    company_current: {
      'ui:widget': 'admin-select-company-widget',
    },
    seniority_baseline_date: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    seniority_start: {
      'ui:help': '以年為單位',
    },
    seniority_end: {
      'ui:help': '以年為單位',
    },
    birthday_date_start: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    birthday_date_end: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    age_start: {
      'ui:help': '以年為單位',
    },
    age_end: {
      'ui:help': '以年為單位',
    },
    expiry_date_start: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
    expiry_date_end: {
      'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
    },
  },
};

function generateSheetColumns(record, exportFields) {
  const data = {
    sex: '',
    residence_address: '',
    mail_address: '',
    substate: '',
    birthday: '',
    join_date: '',
    transform_date: '',
    withdraw_date: '',
    c_qualify: '',
    a_qualify: '',
    educations: '',
    experiences: '',
    data_mode: '',
    inform_address: '',
  };

  data.sex = getUserSexLabel(record.SEX);

  data.residence_address = `${record.residence_address_zip_code || ''}${
    record.residence_address_city
      ? record.residence_address_city.replace('-', '')
      : ''
  }${record.residence_address_detail || ''}`;

  data.mail_address = `${record.mail_address_zip_code || ''}${
    record.mail_address_city ? record.mail_address_city.replace('-', '') : ''
  }${record.mail_address_detail || ''}`;

  data.substate = getUserSubstateLabel(record.substate);

  data.birthday = record.birthday
    ? formatDateStr(new Date(record.birthday)).replace(/-/g, '')
    : '';

  data.join_date = record.join_date
    ? formatDateStr(new Date(record.join_date)).replace(/-/g, '')
    : '';

  data.transform_date = record.transform_date
    ? formatDateStr(new Date(record.transform_date)).replace(/-/g, '')
    : '';

  data.withdraw_date = record.withdraw_date
    ? formatDateStr(new Date(record.withdraw_date)).replace(/-/g, '')
    : '';

  data.c_qualify = record.C_QUALIFY_DATE
    ? `${formatDateStr(new Date(record.C_QUALIFY_DATE)).replace(/-/g, '')}(${
        record.C_QUALIFY_WORD || ''
      }${record.C_QUALIFY_NO || ''})`
    : '';

  data.a_qualify = record.A_QUALIFY_DATE
    ? `${formatDateStr(new Date(record.A_QUALIFY_DATE)).replace(/-/g, '')}(${
        record.A_QUALIFY_WORD
      }${record.A_QUALIFY_NO})`
    : '';

  data.educations = (() => {
    let text = '';

    if (
      record.educations &&
      Array.isArray(record.educations) &&
      record.educations.length > 0
    ) {
      record.educations.forEach((element, i) => {
        const degree = element.DEGREE
          ? `${getUserDegree(element.DEGREE)}:`
          : '';
        const name = `${element.name}` || '';
        const separate =
          i + 1 === record.educations.length ? '' : name ? '\n' : '';

        text += `${degree}${name}${separate}`;
      });
    }

    return text;
  })();

  data.experiences = (() => {
    let text = '';

    if (
      record.experiences &&
      Array.isArray(record.experiences) &&
      record.experiences.length > 0
    ) {
      record.experiences.forEach((element, i) => {
        text += `${element.EVENT || ''}${element.POSITION || ''}${
          i + 1 === record.experiences.length ? '' : '、'
        }`;
      });
    }

    return text;
  })();

  data.inform_address = (() => {
    let text = '';

    if (record.INFORM_TO === 1) {
      //事務所
      text = record.company_current_address;
    } else if (record.INFORM_TO === 2) {
      //律師法第26條陳明收受送達處所
      const zip_code = record.mail_address_zip_code || '';
      const city = record.mail_address_city
        ? record.mail_address_city.replace('-', '')
        : '';
      const detail = record.mail_address_detail || '';

      text = `${zip_code}${city}${detail}`;
    }

    return text;
  })();

  data.data_mode = (() => {
    let text = '';

    const start = new Date(`${exportFields.startDate}T00:00`).getTime();
    const admin_updated = record.admin_updated || 0;
    const user_updated = record.user_updated || 0;
    const hired_time = record.hired_time;

    const effDateString =
      record.state === 5
        ? record.cross_regions &&
          Array.isArray(record.cross_regions) &&
          record.cross_regions.length > 0
          ? `${
              record.cross_regions[
                record.cross_regions.length - 1
              ].EFF_DATE?.split('T')[0] || null
            }`
          : null
        : record.inouts &&
          Array.isArray(record.inouts) &&
          record.inouts.length > 0
        ? `${
            record.inouts[record.inouts.length - 1].EFF_DATE?.split('T')[0] ||
            null
          }`
        : null;

    //Insert
    if (!effDateString) {
      if (hired_time >= start) {
        text = 'I';
        return text;
      }
    }

    if (effDateString) {
      const eff_date = new Date(effDateString).getTime();

      if (eff_date >= start) {
        text = 'I';
        return text;
      }
    }

    //Update
    if (admin_updated >= start || user_updated >= start) {
      text = 'U';
      return text;
    }

    return text;
  })();
  return data;
}

export function transformQuery(queryParams) {
  if (queryParams.qType === '資料異動') {
    const startTime = new Date(`${queryParams.startDate}T00:00+0800`).getTime();
    const endTime = startTime + queryParams.days * 24 * 3600 * 1000;
    const endDate = new Date(endTime).toLocaleDateString('sv');

    const query = {
      $or: [
        {
          inouts: {
            $elemMatch: {
              EFF_DATE: {$gte: queryParams.startDate, $lte: endDate},
            },
          },
        },
        {
          admin_updated: {
            $gte: startTime,
            $lt: endTime,
          },
        },
        {
          user_updated: {
            $gte: startTime,
            $lt: endTime,
          },
        },
      ],
      state: {$in: [1]},
      substate: {$in: ['4', '5', '6']},
      user_type: {$ne: 'extra_member'},
    };

    return query;
  }

  if (queryParams.qType === '入會區間') {
    const query = {};

    if (queryParams.joinDateStart) {
      if (!dtStrExp.test(queryParams.joinDateStart)) {
        alert('查詢入會時間格式錯誤');
        throw new Error();
      }
      query.EFF_DATE = {
        $gte: `${queryParams.joinDateStart}T00:00:00`,
      };
    }

    if (queryParams.joinDateEnd) {
      if (!dtStrExp.test(queryParams.joinDateEnd)) {
        alert('查詢入會時間格式錯誤');
        throw new Error();
      }
      if (query.EFF_DATE) {
        query.EFF_DATE = {
          ...query.EFF_DATE,
          $lte: `${queryParams.joinDateEnd}T23:59:59`,
        };
      } else {
        query.EFF_DATE = {
          $lte: `${queryParams.joinDateEnd}T23:59:59`,
        };
      }
    }

    return {
      inouts: {
        $elemMatch: query,
      },
      state: {$in: [1, 2]},
      substate: {$in: ['4', '5', '6']},
      user_type: {$ne: 'extra_member'},
    };
  }

  if (queryParams.qType === '退會區間') {
    const query = {};

    if (queryParams.exitDateStart) {
      if (!dtStrExp.test(queryParams.exitDateStart)) {
        alert('查詢退會時間格式錯誤');
        throw new Error();
      }
      query.EXPR_DATE = {
        $gte: `${queryParams.exitDateStart}T00:00:00`,
      };
    }

    if (queryParams.exitDateEnd) {
      if (!dtStrExp.test(queryParams.exitDateEnd)) {
        alert('查詢退會時間格式錯誤');
        throw new Error();
      }
      if (query.EXPR_DATE) {
        query.EXPR_DATE = {
          ...query.EXPR_DATE,
          $lte: `${queryParams.exitDateEnd}T23:59:59`,
        };
      } else {
        query.EXPR_DATE = {
          $lte: `${queryParams.exitDateEnd}T23:59:59`,
        };
      }
    }

    return {
      inouts: {
        $elemMatch: query,
      },
      state: {$in: [1, 2, 5]},
      substate: {$in: ['4', '5', '6', '']},
      user_type: {$ne: 'extra_member'},
    };
  }

  if (queryParams.qType === '擇定區間') {
    const query = {};

    if (queryParams.transformDateStart) {
      if (!dtStrExp.test(queryParams.transformDateStart)) {
        alert('查詢轉換時間格式錯誤');
        throw new Error();
      }

      query.TRNS_DATE = {
        $gte: `${queryParams.transformDateStart}T00:00:00`,
      };
    }

    if (queryParams.transformDateEnd) {
      if (!dtStrExp.test(queryParams.transformDateEnd)) {
        alert('查詢轉換時間格式錯誤');
        throw new Error();
      }

      if (query.TRNS_DATE) {
        query.TRNS_DATE = {
          ...query.TRNS_DATE,
          $lte: `${queryParams.transformDateEnd}T23:59:59`,
        };
      } else {
        query.TRNS_DATE = {
          $lte: `${queryParams.transfromDateEnd}T23:59:59`,
        };
      }
    }

    return {
      transforms: {$elemMatch: query},
      state: {$in: [1, 2]},
      substate: {$in: ['4', '5', '6']},
      user_type: {$ne: 'extra_member'},
    };
  }

  if (queryParams.qType === '進階搜尋') {
    const query = {};
    const inoutsQuery = {};
    const transformsQuery = {};

    if (queryParams.owner) {
      query.owner = queryParams.owner;
    }

    if (Array.isArray(queryParams.states) && queryParams.states.length > 0) {
      query.state = {$in: queryParams.states};
    }

    if (
      Array.isArray(queryParams.substates) &&
      queryParams.substates.length > 0
    ) {
      query.substate = {$in: queryParams.substates};
    }

    if (queryParams.sex) {
      query.SEX = queryParams.sex;
    }

    if (queryParams.company_current) {
      query.company_current = queryParams.company_current;
    }

    if (queryParams.school) {
      query.educations = {$elemMatch: {name: {$regex: queryParams.school}}};
    }

    if (queryParams.expertise) {
      query.expertise = {$elemMatch: {name: {$regex: queryParams.expertise}}};
    }

    if (queryParams.birthday_date_start) {
      if (!dtStrExp.test(queryParams.birthday_date_start)) {
        alert('查詢生日時間格式錯誤');
        throw new Error();
      }
      query.birthday = {
        $gte: queryParams.birthday_date_start,
      };
    }

    if (queryParams.birthday_date_end) {
      if (!dtStrExp.test(queryParams.birthday_date_end)) {
        alert('查詢生日時間格式錯誤');
        throw new Error();
      }

      query.birthday = {
        ...query.birthday,
        $lt: queryParams.birthday_date_end,
      };
    }

    if (queryParams.expiry_date_start) {
      if (!dtStrExp.test(queryParams.expiry_date_start)) {
        alert('查詢許可證有效期限格式錯誤');
        throw new Error();
      }

      query.EXPIRY_DATE = {
        $gte: queryParams.expiry_date_start,
      };
    }

    if (queryParams.expiry_date_end) {
      if (!dtStrExp.test(queryParams.expiry_date_end)) {
        alert('查詢許可證有效期限格式錯誤');
        throw new Error();
      }

      query.EXPIRY_DATE = {
        ...query.EXPIRY_DATE,
        $lt: queryParams.expiry_date_end,
      };
    }

    if (queryParams.joinDateStart) {
      if (!dtStrExp.test(queryParams.joinDateStart)) {
        alert('查詢入會時間格式錯誤');
        throw new Error();
      }
      inoutsQuery.EFF_DATE = {
        $gte: `${queryParams.joinDateStart}T00:00:00`,
      };

      query.inouts = {$elemMatch: inoutsQuery};
    }

    if (queryParams.joinDateEnd) {
      if (!dtStrExp.test(queryParams.joinDateEnd)) {
        alert('查詢入會時間格式錯誤');
        throw new Error();
      }
      if (inoutsQuery.EFF_DATE) {
        inoutsQuery.EFF_DATE = {
          ...inoutsQuery.EFF_DATE,
          $lte: `${queryParams.joinDateEnd}T23:59:59`,
        };
      } else {
        inoutsQuery.EFF_DATE = {
          $lte: `${queryParams.joinDateEnd}T23:59:59`,
        };
      }

      query.inouts = {$elemMatch: inoutsQuery};
    }

    if (queryParams.exitDateStart) {
      if (!dtStrExp.test(queryParams.exitDateStart)) {
        alert('查詢退會時間格式錯誤');
        throw new Error();
      }
      inoutsQuery.EXPR_DATE = {
        $gte: `${queryParams.exitDateStart}T00:00:00`,
      };

      query.inouts = {$elemMatch: inoutsQuery};
    }

    if (queryParams.exitDateEnd) {
      if (!dtStrExp.test(queryParams.exitDateEnd)) {
        alert('查詢退會時間格式錯誤');
        throw new Error();
      }
      if (inoutsQuery.EXPR_DATE) {
        inoutsQuery.EXPR_DATE = {
          ...inoutsQuery.EXPR_DATE,
          $lte: `${queryParams.exitDateEnd}T23:59:59`,
        };
      } else {
        inoutsQuery.EXPR_DATE = {
          $lte: `${queryParams.exitDateEnd}T23:59:59`,
        };
      }

      query.inouts = {$elemMatch: inoutsQuery};
    }

    if (queryParams.transformDateStart) {
      if (!dtStrExp.test(queryParams.transformDateStart)) {
        alert('查詢轉換時間格式錯誤');
        throw new Error();
      }

      transformsQuery.TRNS_DATE = {
        $gte: `${queryParams.transformDateStart}T00:00:00`,
      };

      query.transforms = {$elemMatch: transformsQuery};
    }

    if (queryParams.transformDateEnd) {
      if (!dtStrExp.test(queryParams.transformDateEnd)) {
        alert('查詢轉換時間格式錯誤');
        throw new Error();
      }

      if (transformsQuery.TRNS_DATE) {
        transformsQuery.TRNS_DATE = {
          ...transformsQuery.TRNS_DATE,
          $lte: `${queryParams.transformDateEnd}T23:59:59`,
        };
      } else {
        transformsQuery.TRNS_DATE = {
          $lte: `${queryParams.transfromDateEnd}T23:59:59`,
        };
      }

      query.transforms = {$elemMatch: transformsQuery};
    }

    return query;
  }

  return {};
}

export function transformProjection(queryParams) {
  const projection = {
    owner: 1,
    LAWYER_NO: 1,
    NAME_ENG: 1,
    name: 1,
    SEX: 1,
    birthday: 1,
    id_card_number: 1,
    residence_address_zip_code: 1,
    residence_address_city: 1,
    residence_address_detail: 1,
    substate: 1,
    inouts: 1,
    state: 1,
    cross_regions: 1,
    transforms: 1,
    INFORM_BY: 1,
    INFORM_TO: 1,
    C_QUALIFY_DATE: 1,
    C_QUALIFY_NO: 1,
    C_QUALIFY_WORD: 1,
    A_QUALIFY_DATE: 1,
    A_QUALIFY_NO: 1,
    A_QUALIFY_WORD: 1,
    QUALIFY_CTRY: 1,
    educations: 1,
    experiences: 1,
    experiences_tba: 1,
    company_current: 1,
    company_city: 1,
    company_zip_code: 1,
    company_detail: 1,
    company_phone: 1,
    company_fax: 1,
    email: 1,
    EMAIL2: 1,
    PHONE1: 1,
    phone: 1,
    punishments: 1,
    mail_address_zip_code: 1,
    mail_address_city: 1,
    mail_address_detail: 1,
    hired_time: 1,
    ASSISTANT1: 1,
    ASSISTANT2: 1,
    created: 1,
    updated: 1,
    admin_updated: 1,
    user_updated: 1,
  };

  return projection;
}

export async function fetchUprsByBaseline({queryParams, paging, token}) {
  const hasSenioritySetup =
    !!queryParams.seniority_start && !!queryParams.seniority_end;
  const hasAgeSetup =
    (!!queryParams.age_start || queryParams.age_start === 0) &&
    !!queryParams.age_end;

  const inputParams = (() => {
    const returnValue = {};

    if (hasSenioritySetup) {
      returnValue['seniority_start'] = queryParams.seniority_start;
      returnValue['seniority_end'] = queryParams.seniority_end;
      returnValue['seniority_base_date'] = queryParams.seniority_baseline_date
        ? queryParams.seniority_baseline_date
        : new Date().toLocaleDateString('sv');
    }

    if (hasAgeSetup) {
      returnValue['age_start'] =
        queryParams.age_start === 0 ? 1 : queryParams.age_start;
      returnValue['age_end'] = queryParams.age_end;
      returnValue['age_base_date'] = queryParams.age_baseline_date
        ? queryParams.age_baseline_date
        : new Date().toLocaleDateString('sv');
    }

    return returnValue;
  })();

  const results = Object.keys(inputParams).length
    ? await AppActionsEx.fetchUserBaselineResults(inputParams)
    : [];

  if (results.length > 0) {
    return JStorage.fetchDocuments(
      'user_profile',
      {
        owner: {$in: results},
        ...transformQuery(queryParams),
      },
      [queryParams.sorting],
      paging,
      transformProjection(queryParams),
      {token},
    );
  } else {
    return JStorage.fetchDocuments(
      'user_profile',
      transformQuery(queryParams),
      [queryParams.sorting],
      paging,
      transformProjection(queryParams),
      {token},
    );
  }
}

export async function fetchUserProfiles({queryParams, paging, token}) {
  return JStorage.fetchDocuments(
    'user_profile',
    transformQuery(queryParams),
    [queryParams.sorting],
    paging,
    transformProjection(queryParams),
    {token},
  );
}

export async function fetchUprsByPagination({fetchFunc, queryParams, token}) {
  let limit = 1000;
  let uprsResp = null;

  uprsResp = await fetchFunc({
    queryParams,
    paging: {offset: 0, limit},
    token,
  });

  if (uprsResp.total > limit) {
    message.info({
      content: `總回傳數超過 ${limit} 筆，請耐心等候資料回傳`,
      duration: 0,
    });

    const uprsAllResp = {
      total: uprsResp.total,
      results: uprsResp.results,
    };

    const counter = uprsResp.total / limit;
    const promiseArray = [];

    for (let i = 1; i < counter; i++) {
      const promise = fetchFunc({
        queryParams,
        paging: {offset: i * limit, limit},
        token,
      });

      promiseArray.push(promise);
    }

    const resp = await Promise.all(promiseArray);

    for (let i = 0; i < resp.length; i++) {
      uprsAllResp.results = [...uprsAllResp.results, ...resp[i].results];
    }

    return uprsAllResp;
  }

  return uprsResp;
}

export async function exportExcelFile({queryParams, token, setDownloadUrl}) {
  AppActions.setLoading(true);

  try {
    let uprsResp;
    let companiesResp;
    let senioritiesResp;

    if (queryParams.qType === '進階搜尋') {
      const hasBaselineSetup =
        !!queryParams.seniority_baseline_date ||
        !!queryParams.seniority_start ||
        !!queryParams.seniority_end ||
        !!queryParams.age_baseline_date ||
        !!queryParams.age_start ||
        !!queryParams.age_end;

      if (hasBaselineSetup) {
        uprsResp = await fetchUprsByPagination({
          fetchFunc: fetchUprsByBaseline,
          queryParams,
          token,
        });
      } else {
        uprsResp = await fetchUprsByPagination({
          fetchFunc: fetchUserProfiles,
          queryParams,
          token,
        });
      }

      senioritiesResp = await AppActionsEx.getUserSeniorities({
        users_id: uprsResp.results.map((r) => r.owner),
      });

      companiesResp = await JStorage.fetchAllDocuments(
        'Company',
        {
          _id: {
            $in: uprsResp.results
              .filter((r) => !!r.company_current)
              .map((r) => r.company_current)
              .map((id) => ({$oid: id})),
          },
        },
        ['-created'],
        {
          CONAME: 1,
          COFAX1: 1,
          COPHONE1: 1,
          address_zip_code: 1,
          address_city: 1,
          address_detail: 1,
        },
      );
    } else {
      uprsResp = await fetchUprsByPagination({
        fetchFunc: fetchUserProfiles,
        queryParams,
        token,
      });

      companiesResp = await await JStorage.fetchAllDocuments(
        'Company',
        {
          _id: {
            $in: uprsResp.results
              .filter((r) => !!r.company_current)
              .map((r) => r.company_current)
              .map((id) => ({$oid: id})),
          },
        },
        ['-created'],
        {
          CONAME: 1,
          COFAX1: 1,
          COPHONE1: 1,
          address_zip_code: 1,
          address_city: 1,
          address_detail: 1,
        },
      );
    }

    if (!uprsResp?.total) {
      message.error('查無紀錄');
      return;
    }

    uprsResp.results = [...uprsResp.results].map((r) => {
      const returnValue = {
        company_current_name: '',
        company_current_fax: '',
        company_current_address: '',
        join_date: '',
        withdraw_date: '',
        transform_date: '',
        is_punishment: '',
        seniority: '',
        substate: '',
      };

      //company data
      const company = companiesResp.find((c) => c.id === r.company_current);
      if (company) {
        returnValue.company_current_name = company.CONAME ? company.CONAME : '';
        returnValue.company_current_fax = r.company_fax || company.COFAX1 || '';
        returnValue.company_current_phone =
          r.company_phone || company.COPHONE1 || '';

        const zip_code = r.company_zip_code || company.address_zip_code || '';
        const city = r.company_city
          ? r.company_city.replace('-', '')
          : company.address_city
          ? company.address_city.replace('-', '')
          : '';
        const detail = r.company_detail || company.address_detail || '';

        returnValue.company_current_address = `${zip_code}${city}${detail}`;
      }

      // join date and withdraw date
      const inoutRecords =
        Array.isArray(r.inouts) && r.inouts.length > 0 ? r.inouts : null;

      if (inoutRecords) {
        if (inoutRecords.length === 1) {
          const lastOneRecord = inoutRecords[inoutRecords.length - 1];

          returnValue.join_date = lastOneRecord['EFF_DATE']
            ? lastOneRecord['EFF_DATE']
            : null;

          returnValue.withdraw_date =
            r.state !== 1
              ? lastOneRecord['EXPR_DATE']
                ? lastOneRecord['EXPR_DATE'] !== '9999-12-31T00:00:00'
                  ? lastOneRecord['EXPR_DATE']
                  : null
                : null
              : null;
        }

        if (inoutRecords.length > 1) {
          const lastOneRecord = inoutRecords[inoutRecords.length - 1];
          const lastSecondRecord = inoutRecords[inoutRecords.length - 2];

          returnValue.join_date = lastOneRecord['EFF_DATE']
            ? lastOneRecord['EFF_DATE']
            : lastSecondRecord['EFF_DATE'];

          returnValue.withdraw_date =
            r.state !== 1
              ? lastOneRecord['EXPR_DATE']
                ? lastOneRecord['EXPR_DATE'] !== '9999-12-31T00:00:00'
                  ? lastOneRecord['EXPR_DATE']
                  : null
                : lastSecondRecord['EXPR_DATE']
              : null;
        }
      }

      //transform_date
      const transformRecord =
        Array.isArray(r.transforms) && r.transforms.length > 0
          ? r.transforms[r.transforms.length - 1]
          : null;

      returnValue.transform_date = transformRecord
        ? transformRecord['TRNS_DATE']
        : null;

      //is_punishment
      returnValue.is_punishment = (r.punishments || []).length > 0;

      //seniority
      const seniorityRecord = senioritiesResp
        ? senioritiesResp.find((s) => s.owner === r.owner)
        : null;
      if (seniorityRecord) {
        returnValue.seniority = seniorityRecord.seniority;
      }

      //substate
      returnValue.substate =
        queryParams.is_twba_substate_6_setup && r.substate === '6'
          ? '4'
          : r.substate;

      return {
        ...r,
        ...returnValue,
      };
    });

    const link = await generateExcelFile(
      queryParams.qType,
      {
        selectAll: queryParams.selectAllFields,
        fields: queryParams.fields,
        startDate: queryParams.startDate,
      },
      uprsResp.results,
    );
    if (link) {
      setDownloadUrl(link);
    }
  } catch (ex) {
    console.warn(ex);
  } finally {
    AppActions.setLoading(false);
  }
}

export async function generateExcelFile(qType, exportFields, records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);

    let sheetHeader;
    let sheetRows;

    if (qType === '資料異動') {
      sheetHeader = [
        '地區公會編號',
        '地區公會會員編號',
        '會員姓名',
        '會員英文姓名',
        '會員性別',
        '出生年月日',
        '身分證字號',
        '戶籍地址',
        '次狀態',
        '入會日期',
        '擇定日期',
        '退會日期',
        '職前訓練合格證書字號',
        '律師證書字號',
        '學歷',
        '經歷',
        '主事務所或機構律師任職法人之名稱',
        '主事務所或機構律師任職法人之地址',
        '電子郵件信箱',
        '電話',
        '行動電話',
        '傳真電話',
        '曾否受過懲戒或判處罪刑',
        '其他事項',
        '備註',
        '通訊地址',
        '26條陳明收送地址',
        '資料模式',
      ];

      sheetRows = records
        .sort((a, b) => a.name.localeCompare(b.name, 'zh-Hant'))
        .map((record) => {
          const data = generateSheetColumns(record, exportFields);

          return [
            'TPBA',
            record.LAWYER_NO,
            record.name,
            record.NAME_ENG,
            data.sex,
            data.birthday,
            record.id_card_number,
            data.residence_address,
            data.substate,
            data.join_date,
            data.transform_date,
            data.withdraw_date,
            data.c_qualify,
            data.a_qualify,
            data.educations,
            data.experiences,
            record.company_current_name,
            record.company_current_address,
            record.email,
            record.company_current_phone,
            record.phone,
            record.company_current_fax,
            record.is_punishment ? '有' : '無',
            '', // TODO: 其他事項 field
            record.NOTE,
            data.mail_address,
            data.inform_address,
            data.data_mode,
          ];
        });
    } else if (
      qType === '入會區間' ||
      qType === '退會區間' ||
      qType === '擇定區間'
    ) {
      sheetHeader = [
        '地區公會編號',
        '地區公會會員編號',
        '會員姓名',
        '會員英文姓名',
        '會員性別',
        '出生年月日',
        '身分證字號',
        '戶籍地址',
        '次狀態',
        '入會日期',
        '擇定日期',
        '退會日期',
        '職前訓練合格證書字號',
        '律師證書字號',
        '學歷',
        '經歷',
        '主事務所或機構律師任職法人之名稱',
        '主事務所或機構律師任職法人之地址',
        '電子郵件信箱',
        '電話',
        '行動電話',
        '傳真電話',
        '曾否受過懲戒或判處罪刑',
        '其他事項',
        '備註',
        '通訊地址',
        '26條陳明收送地址',
        '資料模式',
      ];

      sheetRows = records
        .sort((a, b) => a.name.localeCompare(b.name, 'zh-Hant'))
        .map((record) => {
          const data = generateSheetColumns(record, exportFields);
          return [
            'TPBA',
            record.LAWYER_NO,
            record.name,
            record.NAME_ENG,
            data.sex,
            data.birthday,
            record.id_card_number,
            data.residence_address,
            data.substate,
            data.join_date,
            data.transform_date,
            data.withdraw_date,
            data.c_qualify,
            data.a_qualify,
            data.educations,
            data.experiences,
            record.company_current_name,
            record.company_current_address,
            record.email,
            record.company_current_phone,
            record.phone,
            record.company_current_fax,
            record.is_punishment ? '有' : '無',
            '', // TODO: 其他事項 field
            record.NOTE,
            data.mail_address,
            data.inform_address,
            data.data_mode,
          ];
        });
    } else if (qType === '進階搜尋') {
      const selectedFields = exportFields.selectAll
        ? EXPORT_FIELDS
        : EXPORT_FIELDS.filter(
            (obj) =>
              exportFields.fields.findIndex((key) => key === obj.key) > -1,
          );

      sheetHeader = selectedFields.map((obj) => obj.name);

      sheetRows = records.map((upr, index) => {
        const helper = new ExportHelper({});
        const cct = helper.getCompany(upr.company_current);

        const returnValues = selectedFields.map((f) => {
          if (f.key === 'serial_number') {
            return index + 1;
          } else if (f.key === 'NAME_ENG') {
            return upr.NAME_ENG;
          } else if (f.key === 'state') {
            return getUserStateLabel(upr.state);
          } else if (f.key === 'substate') {
            return getUserSubstateLabel(upr.substate);
          } else if (f.key === 'SEX') {
            return getUserSexLabel(upr.SEX);
          } else if (f.key === 'birthday') {
            return upr.birthday ? formatDateStr(new Date(upr.birthday)) : '';
          } else if (f.key === 'mail_address') {
            const mail_address = `${upr.mail_address_zip_code || ''} ${
              upr.mail_address_city || ''
            } ${upr.mail_address_detail || ''}`;

            return mail_address;
          } else if (f.key === 'residence_address') {
            const residence_address = `${
              upr.residence_address_zip_code || ''
            } ${upr.residence_address_city || ''} ${
              upr.residence_address_detail || ''
            }`;

            return residence_address;
          } else if (f.key === 'company_current_name') {
            return cct.CONAME;
          } else if (f.key === 'company_current_address') {
            const cct_address = `${
              upr.company_zip_code || cct.address_zip_code || ''
            } ${upr.company_city || cct.address_city || ''} ${
              upr.company_detail || cct.address_detail || ''
            }`;

            return cct_address;
          } else if (f.key === 'company_current_phone') {
            return upr.company_phone || cct.COPHONE1 || '';
          } else if (f.key === 'company_current_fax') {
            return upr.company_fax || cct.COFAX1 || '';
          } else if (f.key === 'INFORM_TO') {
            const detail = (() => {
              if (upr.INFORM_TO === 1) {
                const cct_address = `${
                  upr.company_zip_code || cct.address_zip_code || ''
                } ${upr.company_city || cct.address_city || ''} ${
                  upr.company_detail || cct.address_detail || ''
                }`;

                return `${cct_address}`;
              } else if (upr.INFORM_TO === 2) {
                const mail_address = `${upr.mail_address_zip_code || ''} ${
                  upr.mail_address_city || ''
                } ${upr.mail_address_detail || ''}`;

                return `${mail_address}`;
              } else {
                return '';
              }
            })();

            return `${detail}`;
          } else if (f.key === 'INFORM_BY') {
            return getUserInformByLabel(upr.INFORM_BY);
          } else if (f.key === 'age') {
            const today = dayjs(new Date());
            const birthday = dayjs(new Date(upr.birthday));
            const diff = today.diff(birthday, 'd');
            const age = Math.floor(diff / 365.25);

            return age || 0;
          } else if (f.key === 'A_QUALIFY') {
            const display = `${upr.A_QUALIFY_DATE?.split('T')[0] || ''}(${
              upr.A_QUALIFY_WORD || ''
            }${upr.A_QUALIFY_NO || ''})`;
            return display;
          } else if (f.key === 'experiences_tba') {
            let text = ``;

            if (
              Array.isArray(upr.experiences_tba) &&
              upr.experiences_tba.length > 0
            ) {
              upr.experiences_tba.forEach(
                (r, i) =>
                  (text += `${r.committee_name || ''} ${
                    getUserExperienceTbaPositionLabel(r.POSITION) !== '無'
                      ? getUserExperienceTbaPositionLabel(r.POSITION)
                      : ''
                  }${i + 1 !== upr.experiences_tba.length ? '、' : ''}`),
              );
            }

            return text || '無';
          } else if (f.key === 'punishments') {
            let text = ``;

            if (Array.isArray(upr.punishments) && upr.punishments.length > 0) {
              upr.punishments.forEach(
                (r, i) =>
                  (text += `${getUserPunishmentTypeLabel(r.KIND)} ${
                    r.MINISTRY || ''
                  }${i + 1 !== upr.punishments.length ? '、' : ''}`),
              );
            }

            return text || '無';
          } else if (f.key === 'experiences') {
            let text = ``;

            if (Array.isArray(upr.experiences) && upr.experiences.length > 0) {
              upr.experiences.forEach(
                (r, i) =>
                  (text += `${r.EVENT} ${r.POSITION || ''}${
                    i + 1 !== upr.experiences.length ? '、' : ''
                  }`),
              );
            }

            return text || '無';
          } else if (f.key === 'join_start_date') {
            const display = (() => {
              if (upr.state === 1 || upr.state === 2) {
                if (
                  upr.inouts &&
                  Array.isArray(upr.inouts) &&
                  upr.inouts.length > 0
                ) {
                  const _inouts = [...upr.inouts]
                    .filter((r) => !!r.EFF_DATE)
                    .map((r) => ({
                      ...r,
                      EFF_DATE: r.EFF_DATE.split('T')[0],
                    }))
                    .sort(
                      (a, b) =>
                        new Date(b.EFF_DATE).getTime() -
                        new Date(a.EFF_DATE).getTime(),
                    );

                  return _inouts.length > 0 ? _inouts[0].EFF_DATE : null;
                }

                return;
              }
            })();

            return display || '無';
          } else if (f.key === 'join_end_date') {
            const display = (() => {
              if (upr.state === 1 || upr.state === 2) {
                if (
                  upr.inouts &&
                  Array.isArray(upr.inouts) &&
                  upr.inouts.length > 0
                ) {
                  const _inouts = [...upr.inouts]
                    .filter((r) => !!r.EFF_DATE)
                    .map((r) => ({
                      ...r,
                      EFF_DATE: r.EFF_DATE.split('T')[0],
                    }))
                    .sort(
                      (a, b) =>
                        new Date(b.EFF_DATE).getTime() -
                        new Date(a.EFF_DATE).getTime(),
                    );

                  return _inouts.length > 0
                    ? _inouts[0].EXPR_DATE !== '9999-12-31T00:00:00'
                      ? _inouts[0].EXPR_DATE
                        ? _inouts[0].EXPR_DATE.split('T')[0]
                        : null
                      : ''
                    : null;
                }
              }
            })();

            return display || '無';
          } else if (f.key === 'cross_start_date') {
            const display = (() => {
              if (upr.state === 5) {
                if (
                  upr.cross_regions &&
                  Array.isArray(upr.cross_regions) &&
                  upr.cross_regions.length > 0
                ) {
                  const _cross_regions = [...upr.cross_regions]
                    .filter((r) => !!r.EFF_DATE)
                    .map((r) => ({
                      ...r,
                      EFF_DATE: r.EFF_DATE.split('T')[0],
                    }))
                    .sort(
                      (a, b) =>
                        new Date(b.EFF_DATE).getTime() -
                        new Date(a.EFF_DATE).getTime(),
                    );

                  return _cross_regions.length > 0
                    ? _cross_regions[0].EFF_DATE
                    : null;
                }
              }
            })();

            return display || '無';
          } else if (f.key === 'cross_end_date') {
            const display = (() => {
              if (upr.state === 5) {
                if (
                  upr.cross_regions &&
                  Array.isArray(upr.cross_regions) &&
                  upr.cross_regions.length > 0
                ) {
                  const _cross_regions = [...upr.cross_regions]
                    .filter((r) => !!r.EFF_DATE)
                    .map((r) => ({
                      ...r,
                      EFF_DATE: r.EFF_DATE.split('T')[0],
                    }))
                    .sort(
                      (a, b) =>
                        new Date(b.EFF_DATE).getTime() -
                        new Date(a.EFF_DATE).getTime(),
                    );

                  return _cross_regions.length > 0
                    ? _cross_regions[0].EXPR_DATE !== '9999-12-31T00:00:00'
                      ? _cross_regions[0].EXPR_DATE
                        ? _cross_regions[0].EXPR_DATE.split('T')[0]
                        : null
                      : ''
                    : null;
                }
              }
            })();

            return display || '無';
          }

          return upr[f.key];
        });

        return [...returnValues];
      });
    }

    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([sheetHeader, ...sheetRows]);

    window.XLSX.utils.book_append_sheet(wb, ws, '使用者列表');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );

    await AppActions.delay(600);
    message.success('成功創建下載連結');

    await AppActions.delay(1000);
    message.destroy();

    return objUrl;
  } catch (ex) {
    console.warn('generateExcelFile ex', ex);
  } finally {
    AppActions.setLoading(false);
  }
}
